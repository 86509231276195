import React from 'react';

import Head from '../components/Head';
import Layout from '../components/layout';
import Tech from '../containers/Tech';

const TechPage = () => (
  <Layout>
    <Head
      title="뱅크샐러드 공식 블로그 | 기술 블로그"
      description="뱅크샐러드 공식 기술 블로그입니다. 뱅크샐러드의 기술, 문화, 행사 등 최신 소식과 함께  뱅크샐러드가 겪은 다양한 경험을 공유합니다."
      keywords={[
        '뱅크샐러드 기술블로그',
        '기술블로그',
        '핀테크',
        '스타트업',
        '개발자',
        '디자이너',
        'PM',
        '기획자',
        '뱅샐',
        'banksalad',
        '이승민',
        'Github',
        '안드로이드',
        '아이폰',
        'Android',
        'iOS',
        'Frontend',
        '프론트',
        '프론트엔드',
        '프런트엔드',
        '백엔드',
        '데브옵스',
        'backend',
        'devops',
        '채용',
      ]}
    />
    <Tech />
  </Layout>
);

export default TechPage;
