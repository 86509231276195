import React from 'react';

import Hero from '../../components/Hero/Hero';
import PostListView from '../../components/PostListView';
import { BLOG_CATEGORY } from '../../config';
import useTechBlogLatestList from '../../hooks/data/useTechBlogLatestList';
import HERO_MOBILE from '../../images/tech/m_main_hero.png';
import HERO_PC from '../../images/tech/main_hero.png';

const Tech: React.FC = () => (
  <div>
    <Hero
      subTitle="뱅크샐러드 기술 블로그"
      title="더 나은 내가 되는 곳, <br />뱅크샐러드"
      cover={HERO_PC}
      mCover={HERO_MOBILE}
    />
    <PostListView category={BLOG_CATEGORY.TECH} contentsSelector={useTechBlogLatestList} />
  </div>
);

export default Tech;
