import styled from 'styled-components';
import { themeGet } from 'styled-system';

interface StyleProps {
  backgroundPCImage?: string;
  backgroundMobileImage?: string;
}

const S = {
  section: styled.section<StyleProps>`
    width: 100%;
    height: 560px;
    background-color: #9ee4d0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-image: url(${props => props.backgroundPCImage});
    @media (max-width: 768px) {
      background-image: url(${props => props.backgroundMobileImage});
      height: 510px;
    }
  `,
  effect: styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 73%);
    z-index: 1;
  `,
  layout: styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  `,
  subTitle: styled.p`
    font-size: ${themeGet('fontSizes.4', '16')}px;
    font-weight: bold;
    letter-spacing: 1px;
    color: ${themeGet('colors.white', '#fff')};
    opacity: 0.7;
    margin-bottom: 12px;
    text-transform: uppercase;
    @media (max-width: 576px) {
      font-size: ${themeGet('fontSizes.3', '14')}px;
      font-weight: ${themeGet('fontWeights.6', 'bold')};
      letter-spacing: -0.44px;
    }
  `,
  title: styled.h1`
    font-size: 48px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -2.2px;
    margin-bottom: 52px;
    color: ${themeGet('colors.white', '#fff')};
    word-break: keep-all;
    @media (max-width: 992px) {
      font-size: 48px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 576px) {
      width: 80%;
      font-size: 32px;
      line-height: 1.25;
      margin-bottom: 40px;
    }
    @media (max-width: 370px) {
      width: 90%;
    }
  `,
};

export default S;
