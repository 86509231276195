import React from 'react';
import S from './Hero.style';

interface Props {
  subTitle: string;
  title: string;
  cover: string;
  mCover: string;
}

const Hero: React.FC<Props> = ({ subTitle, title, cover, mCover }) => (
  <S.section backgroundPCImage={cover} backgroundMobileImage={mCover}>
    <S.effect />
    <S.layout>
      <S.subTitle>{subTitle}</S.subTitle>
      <S.title dangerouslySetInnerHTML={{ __html: title }} />
    </S.layout>
  </S.section>
);

export default Hero;
